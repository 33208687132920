import { FormHorizontal, FormHorizontalProps } from '~/components/form/layout';
import { useValibotFieldError, useValibotFieldTouched } from '~/components/valibot/ValibotForm';

export default function ValibotFormHorizontal({
  name,
  ...props
}: Omit<FormHorizontalProps, 'error'> & {
  name: string;
}) {
  const error = useValibotFieldError(name);
  const touched = useValibotFieldTouched(name);

  return <FormHorizontal {...props} error={touched && error ? error : undefined} />;
}
